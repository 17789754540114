/*-------Global Stylings-------*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  font-family: 'Comfortaa', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #01B1AF;
  text-shadow: 2px 2px 3px white;
}

p, span, h2, li {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: white;
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

strong {
  font-weight: 900;
  text-shadow: 0px 0px 2px rgb(64, 224, 208);
}

iframe {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
}

/*-----Scroll Bar Stylings */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  background: #01B1AF;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

/*-----End Scroll Bar Stylings */

/*-------End Global Stylings-------*/