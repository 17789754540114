/* Grid Styles */

.grid {
  display: grid;
  gap: 1%;
  background-image: radial-gradient(circle, rgb(50, 20, 50), black);
  width: 100vw;
  height: 100vh;
  grid-template-columns: repeat(auto-fit, minmax(23vw, 1fr));
  padding: 1%;
  grid-auto-rows: 13vh;
  position: fixed;
}

.gridItem {
  position: relative;
  color: white;
  height: 100%;
  width: 100%;
}

.gridItem .overlay {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: .5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .5s;
}

.gridItem .overlay:hover {
  opacity: 1;
}

.gridItem .overlay i {
  color: #01B1AF;
  font-size: 5rem;
}

/* End Grid Styles */

/* Main Video Tile Styles */

.mainTile {
  grid-column: span 3;
  grid-row: span 5;
  color: white;
  transition: box-shadow .5s;
  background-color: black;
}

.mainTile:hover {
  box-shadow: 2px 2px 5px gray;
  cursor: pointer;
}

/* End  Main Video Tile Styles */

/* Action Tile Styles */

.actionTile {
  transition: box-shadow .5s, transform .5s;
}

.actionTile:hover {
  box-shadow: 2px 2px 3px gray;
  cursor: pointer;
  transform: scale(1.01);
}

/* End Action Tile Styles */

/* Header Styles */

.header {
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  padding: 1% 0;
  text-align: center;
}

.header .projectInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
  flex-wrap: wrap;
  width: 100%;
}

.header .projectInfoContainer h2 {
  display: inline-block;
  text-shadow: 1px 1px 1px #01B1AF;
  letter-spacing: 3px;
  padding-right: 1%;
  border-right: 3px ridge rgba(1, 177, 175, .5);
  text-align: center;
}

.header .projectInfoContainer .videoTime {
  padding: 0 2%;
  font-size: 1.5rem;
  font-weight: 600;
}

.header .projectInfoContainer i {
  border-left: 3px ridge rgba(1, 177, 175, .5);
  font-size: 2.5rem;
  color: #01B1AF;
  text-shadow: 1px 1px 2px white;
  padding-left: 2%;
}

.header .projectInfoContainer i span {
  font-size: 1.5rem;
  vertical-align: middle;
  opacity: .3;
  transition: opacity .5s;
  white-space: nowrap;
}

.header .projectInfoContainer .timeDivide {
  color: rgba(1, 177, 175, .5);
}

.header .projectInfoContainer i:hover {
  cursor: pointer;
  text-shadow: 0px 0px 3px white;
}

.header .projectInfoContainer i:hover span {
  opacity: 1;
  text-shadow: 0px 0px 5px #01B1AF;
}

.header .fa-fist-raised {
  opacity: 0;
  transition: opacity 2s;
  color: black;
}

.header .fa-fist-raised:hover {
  opacity: 1;
}

/* End Header Styles */

/* Next Button Tile Styles */

.nextTile {
  padding-bottom: 2%;
}

.nextTile, .previousTile {
  grid-row: span 2;
  grid-column: span 1;
  color: white;
  background-color: black;
}

.nextTile img, .previousTile img {
  width: 100%;
  height: 100%;
  opacity: 1;
  filter: brightness(80%);
  object-fit: cover;
}

.nextTile .overlay p, .previousTile .overlay p {
  text-align: center;
  opacity: 0;
  font-weight: 900;
  font-size: 1.8rem;
  text-shadow: 2px 2px black;
}

.nextTile:hover p, .previousTile:hover p {
  opacity: 1;
}

.nextTile .overlay i, .previousTile .overlay i {
  text-align: center;
  text-shadow: 2px 2px 2px black;
}

/* End Next Button Tile Styles */

/* Previous Button Tile Styles */

.previewTile {
  margin-top: 2%;
  grid-row: span 5;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 4%;
  border-top: 3px ridge rgba(1, 177, 175, .5);
  gap: 2%
}

.previewTile .thumbnailContainer {
  position: relative;
  min-height: 300px;
  background-color: black;
}

.previewTile img {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  object-fit: cover;
}

.previewTile .overlay h2 {
  opacity: 0;
  transition: .5s opacity;
  text-align: center;
}

.previewTile .overlay:hover {
  cursor: pointer;
}

.previewTile .overlay:hover h2 {
  opacity: 1;
}

/* End Previous Button Tile Styles */

/* Social Links Styles */

.socialLinks {
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 2%;
}

.socialLinks i {
  font-size: 3rem;
  color: #01B1AF;
  text-shadow: 1px 1px 2px white;
  display: block;
  text-align: center;
}

.socialLinks i span {
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  padding-top: 5%;
  display: block;
  text-align: center;
  opacity: .3;
  transition: opacity .5s;
}

.socialLinks i:hover {
  cursor: pointer;
  text-shadow: 0px 0px 3px white;
}

.socialLinks i:hover span {
  text-shadow: 0px 0px 5px #01B1AF;
  opacity: 1;
}

/* End Social Links Styles */

/* Module Styles */

.moduleContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moduleContainer .moduleBackground {
  background-color: rgba(0, 0, 0, .5);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.moduleContainer .module {
  background-color: rgba(1, 177, 175, .7);
  max-width: 50vw;
  padding: 5%;
  box-shadow: 10px 10px 5px black;
  position: relative;
  z-index: 1;
}

.moduleContainer .module i {
  font-size: 3rem;
  position: absolute;
  color: white;
  text-shadow: 1px 1px 1px black;
  right: 5%;
  top: 5%;
  opacity: .7;
}

.moduleContainer .module i:hover {
  cursor: pointer;
  opacity: 1;
  text-shadow: none;
}

.moduleContainer .module h2 {
  font-size: 3rem;
  text-align: center;
  text-decoration-color: turquoise;
  text-shadow: 2px 2px 2px black;
}

.moduleContainer .module ul {
  margin-top: 10%;
}

.moduleContainer .module li {
  font-size: 1.5rem;
  font-weight: 600;
}

/* End Module Styles */

/* ----- Media Queries ----- */

@media (max-width: 1700px), (max-height: 880px) {
  h1 {
    font-size: 3rem;
  }
  .header .projectInfoContainer h2, .header .projectInfoContainer i span, .socialLinks i span, .nextTile .overlay p, .previousTile .overlay p, .previewTile .overlay h2, .moduleContainer .module li {
    font-size: 1.2rem;
  }
  .header .projectInfoContainer .videoTime {
    font-size: 1.1rem;
  }
  .header .projectInfoContainer i, .socialLinks i, .moduleContainer .module i {
    font-size: 2rem;
  }
  .gridItem .overlay i {
    font-size: 3rem;
  }
}

@media (max-width: 1200px), (max-height: 650px) {
  h1, .moduleContainer .module h2 {
    font-size: 2rem;
    text-shadow: 1px 1px 2px white;
  }
  .moduleContainer .module {
    padding: 5% 15%;
    max-width: 75vw;
  }
}

@media (max-width: 1000px), (max-height: 600px) {
  .grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-auto-rows: 12.5%;
    max-height: 100vh;
  }
  h1, .moduleContainer .module h2 {
    font-size: 1.5rem;
    text-align: center;
  }
  .header .projectInfoContainer h2, .header .projectInfoContainer i span, .socialLinks i span, .nextTile .overlay p, .previousTile .overlay p, .previewTile .overlay h2, .moduleContainer .module li {
    font-size: 1rem;
  }
  .header .projectInfoContainer .videoTime {
    font-size: 1rem;
  }
  .header .projectInfoContainer i, .socialLinks i, .socialLinks i, .moduleContainer .module i {
    font-size: 1.5rem;
    text-shadow: 0px 0px 1px white;
  }
  .gridItem .overlay i {
    font-size: 1.5rem;
  }
  .mainTile {
    grid-column: span 4;
    grid-row: span 3;
  }
  .previewTile {
    grid-row: span 2;
    grid-column: span 3;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    border: none;
    margin-top: 0;
    padding-top: 0;
  }
  .previewTile .overlay {
    width: 100%;
    height: 100%;
  }
  .nextTile, .previousTile {
    grid-row: span 1;
  }
  .previewTile .thumbnailContainer {
    display: inline-block;
    width: 300px;
    height: 100%;
  }
  .previewTile img {
    padding-bottom: 0;
    padding-right: 0;
    width: auto;
    height: 100%;
    width: 300px;
    object-fit: cover;
  }
  .header, .socialLinks {
    margin-top: 5%;
    grid-column: span 4;
    align-items: center;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header .projectInfoContainer {
    width: 100%;
    justify-content: center;
  }
  .header .projectInfoContainer h2 {
    display: inline-block;
    width: auto;
    padding-right: 2%;
  }
  .header .projectInfoContainer .videoTime {
    width: auto;
    display: inline-block;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 1.5rem;
  }
  .header .projectInfoContainer h2, .header .projectInfoContainer i span, .socialLinks i span, .nextTile .overlay p, .previousTile .overlay p, .header .projectInfoContainer .videoTime {
    font-size: .8rem;
  }
  .gridItem .overlay i {
    font-size: 2.5rem;
  }
}

@media (max-width: 550px) {
  h1 {
    font-size: 1.3rem;
  }
  .header .projectInfoContainer h2, .header .projectInfoContainer i span, .socialLinks i span, .header .projectInfoContainer .videoTime {
    font-size: .8rem;
  }
  .nextTile .overlay p, .previousTile .overlay p {
    font-size: .55rem;
  }
  .gridItem .overlay i {
    font-size: 1.5rem;
  }
}

/* ----- End Media Queries ----- */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.moduleContainer {
  animation: fadeIn .5s;
}